'use client'
import Collapse from '@component/collapse'
import classNames from 'classnames'
import { isFunction } from 'lodash-es'
import Image from 'next/image'
import { useCallback } from 'react'
import { useBoolean } from 'react-use'

export default function HomeServicesLayout({
  icon,
  title,
  right,
  children,
  open = false,
  isNew = false,
  secondary,
  onToggle,
}) {

  const [visible, setVisible] = useBoolean(open)
  const toggleVisible = useCallback(() => {
    setVisible()
    isFunction(onToggle) && onToggle(!visible)
  }, [setVisible, onToggle, visible])

  return (
    <div className='border-t'>
      <div className='py-20 flex items-center cursor-pointer' onClick={toggleVisible}>
        {icon && <Image src={icon} alt={title} unoptimized className='w-22 h-22 mr-10 md:w-32 md:h-32 md:mr-22'></Image>}
        <span className='text-font text-lg md:text-2xl font-bold'>{title}</span>
        {
          isNew && (
            <span className="ml-12 h-24 inline-flex items-center px-10 bg-primary-light rounded-lg text-primary text-sm font-medium">NEW</span>
          )
        }
        <div className='ml-auto mr-18'>{right}</div>
        <i className={classNames('icon !text-1xl text-primary font-bold', visible ? 'icon-arrow-up' : 'icon-arrow-down')}></i>
      </div>
      {secondary}
      <Collapse show={visible}>
        <div>{children}</div>
      </Collapse>
    </div>
  )
}