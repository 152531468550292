'use client'

import Form from '@component/form'
import FormButton from '@component/form/button'
import FormInput from '@component/form/input'
import Select from '@component/select'
import { CALCULATOR_INTEREST_RATE, CALCULATOR_LOAN_TERM, CALCULATOR_REPAYMENT } from '@config'
import calculatedLoan from '@plugin/calculator'
import { propertyDataLayer } from '@plugin/google/gtm/modules/property'
import { mumFormat, toPrice } from '@utils'
import classNames from 'classnames'
import { find, get, mapValues, replace } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { useCalculatorContext } from './context'

// price: 1000000,
// deposit: 200000,
// rate: 7.31,
// term: 30,
// frequency: 3,
// method: 1
export default function CalculatorContent() {

  const {
    showResult,
    toggleResult,
    data,
    setData,
    frequency,
    setFrequency,
    loanTerm,
    setLoanTerm,
    interestRate,
    setInterestRate,
    changeField
  } = useCalculatorContext()

  const submitHandle = useCallback(d => {
    setData(d)
    toggleResult(true)
  }, [toggleResult, setData])

  const changeHandle = useCallback(d => {
    const price = toPrice(replace(d.price, /[^\d.]/g, ''), '', '0,0')
    const deposit = toPrice(replace(d.deposit, /[^\d.]/g, ''), '', '0,0')
    setData({ price, deposit })
  }, [setData])

  const handleFireEvent = useCallback((eventType) => {
    setData(data => {
      let { price, deposit } = data
      price = replace(price, /[^\d.]/g, '')
      deposit = replace(deposit, /[^\d.]/g, '')
      propertyDataLayer.calculator(
        { price, deposit, rate: interestRate, term: loanTerm },
        get(find(CALCULATOR_REPAYMENT, { value: frequency }), 'label'),
        eventType
      )
      return data
    })

  }, [setData, frequency, loanTerm, interestRate])
  const handleChangeField = useCallback((setFn, eventType) => {
    return (val) => {
      changeField(setFn)(val)
      handleFireEvent(eventType)
    }
  }, [changeField, handleFireEvent])

  const result = useMemo(() => {
    if (!showResult) return {}

    let { price, deposit } = data
    price = replace(price, /[^\d.]/g, '')
    deposit = replace(deposit, /[^\d.]/g, '')
    const r = calculatedLoan({ frequency, price, deposit, interestRate, loanTerm })

    return mapValues(r, val => `$${mumFormat(val, '0,0.[00]')}`)
  }, [data, frequency, loanTerm, interestRate, showResult])

  return (
    <div className='grid grid-cols-1 gap-y-20 gap-x-30 md:grid-cols-2 pb-20'>
      <div>
        <Form data={data} onChange={changeHandle} onSubmit={submitHandle}>
          <FormInput prop='price' label='Property price' prefix={<span className='flex items-center pl-16'>$</span>} onBlur={() => handleFireEvent('Interactions')}></FormInput>
          <FormInput prop='deposit' label='Deposit' prefix={<span className='flex items-center pl-16'>$</span>} onBlur={() => handleFireEvent('Interactions')}></FormInput>
          <FormButton outline onClick={() => handleFireEvent('click calculate')}>Calculate</FormButton>
        </Form>
      </div>
      <div className={classNames('grid gap-20 grid-cols-1', { 'sm:grid-cols-2': !showResult })}>
        <div className='flex flex-col justify-between space-y-10'>
          <ResultRow
            label={<Select data={CALCULATOR_REPAYMENT} value={frequency} onChange={handleChangeField(setFrequency, 'Interactions')} popWidth={230}></Select>}
            value={get(result, 'repay')}
          ></ResultRow>
          <ResultRow label='Loan term'>
            <Select data={CALCULATOR_LOAN_TERM} value={loanTerm} onChange={handleChangeField(setLoanTerm, 'Interactions')} popWidth={230} labelClassName='sm:text-xl'></Select>
          </ResultRow>
          <ResultRow label='Total interest' value={get(result, 'interest')}></ResultRow>
          <ResultRow label='Interest rate'>
            <Select data={CALCULATOR_INTEREST_RATE} value={interestRate} onChange={handleChangeField(setInterestRate, 'Interactions')} popWidth={230} labelClassName='sm:text-xl'></Select>
          </ResultRow>
          <ResultRow label='Total cost' value={get(result, 'total')}></ResultRow>
        </div>
        {
          !showResult && (
            <div className='border rounded-sm p-20 flex items-center text-font text-xl'>Press calculate to work out your mortgage repayments and how quickly you can pay off your loan.</div>
          )
        }
      </div>
    </div>
  )
}

function ResultRow({
  label,
  value,
  children
}) {

  const { showResult } = useCalculatorContext()
  return (
    <div className={classNames('grid gap-x-30', showResult ? 'grid-cols-2' : ' grid-cols-1')}>
      {label && (
        <div>
          <span className='text-font text-base font-normal inline-block'>{label}</span>
        </div>
      )}
      {
        showResult && (
          <div>
            {children && <div className='inline-block'>{children}</div>}
            {value && <span className='text-font text-xl font-medium'>{value}</span>}
          </div>
        )
      }
    </div>
  )
}