'use client'

import Calculator from '@component/pages/calculator'
import IconSaveMoney from '@icon/house/save-money.svg?url'
import { propertyDataLayer } from '@plugin/google/gtm/modules/property'
import HomeServicesLayout from '../base'

export default function Banking({
  price,
  expand = false,
}) {

  function handleToggle(opened = false) {
    if (opened) {
      propertyDataLayer.calculatorOpened()
    } else {
      propertyDataLayer.calculatorClosed()
    }
  }
  return (
    <HomeServicesLayout
      title='Mortgage calculator'
      icon={IconSaveMoney}
      onToggle={handleToggle}
      open={expand}
    >
      <Calculator price={price}></Calculator>
    </HomeServicesLayout>
  )
}