'use client'

import CalculatorContent from './content'
import CalculatorContext, { useCalculatorState } from './context'

export default function Calculator({
  price = 1000000,
  deposit = price * .2,
  frequency = 3,
  loanTerm = 30,
  interestRate = 7.31
}) {

  const state = useCalculatorState({ price, deposit, frequency, loanTerm, interestRate })
  return (
    <CalculatorContext.Provider value={{ ...state, price, deposit }}>
      <CalculatorContent></CalculatorContent>
    </CalculatorContext.Provider>
  )
}